<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="maincard">
				<Toast/>
				<Toolbar class="p-mb-0">
					<template #left>
						<!--Button label="Új" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" /-->
						<!--Button label="Töröl" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedTools || !selectedTools.length" /-->
						<!--Button label="Teszt" icon="pi pi-plus" class="p-button-info p-mr-2" @click="teszt" style="margin-left: 7px" /-->
                        <!--Dropdown v-model="toolClass" :options="codesToolClass" optionLabel="value" optionValue="code" placeholder="Select a Class" /-->
					</template>

					<!--template slot="right">
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-d-inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template-->
				</Toolbar>


                <!-- Táblázat -->

				<DataTable ref="dt" :value="tools" v-model:expandedRows="expandedRows" dataKey="id" :paginator="true" :rows="10" 
                            sortField="accortool" :sortOrder="1" :loading="loading" @row-click="expandRow"
                            v-model:filters="filters" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25,100]"
							currentPageReportTemplate="{first} - {last} / {totalRecords}" class="p-datatable-customers p-datatable-sm p-datatable-hoverable-rows ">
					<template #header>
						<div class="table-header">
                            <div>
                                <Button label="Új" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
                                <h5 class="p-m-0 p-d-inline-block">Karbantartás: Eszközök</h5>
                            </div>
							<span class="p-input-icon-left">
                                <i :class="(loadingSearch) ? ['pi', 'pi-spin', 'pi-spinner'] : ['pi', 'pi-search']"/>
                                <InputText v-model="searchGlobal" placeholder="Keresés..." />
                            </span>
						</div>
					</template>

					<Column :expander="true" headerStyle="width: 3rem" />
					<Column field="accortool_name" header="Típus" sortable headerStyle="width: 8rem" ></Column>
					<Column field="accname" header="Megnevezés" sortable headerStyle="width: 25rem"></Column>
					<Column field="accgroup" header="Csoport" sortable></Column>
					<Column field="oep_name" header="NEAK támogatott" sortable></Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editTool(slotProps.data.id)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-warning" @click="confirmDeleteTool(slotProps.data)" />
						</template>
					</Column>
					<template #expansion="slotProps">
                        <Card>
                        <template #content>
                            <div class="p-grid">
                                <div class="p-col" style="margin-left: 2rem">
                                    <p class="p-d-inline-block" style="margin-right: 1rem">Megjegyzés:</p>
                                    <p class="p-d-inline" style="white-space: pre-wrap" v-html="slotProps.data.note"></p>
                                </div>
                                <!--div class="p-col">Város: {{ slotProps.data.city }}</div-->
                            </div>
                        </template>                            
                        </Card>
                    </template>				
                </DataTable>


                <!-- Szerkesztő ablak -->

				<Dialog id="dialog" v-model:visible="toolDialog" :style="{width: '450px', 'margin-top': '16px' }" 
                    header="Eszköz" :modal="true" class="p-fluid"
                    @keydown.enter.ctrl="saveTool()" @keyup.esc="hideDialog()">
					<div class="p-field">
                        <SelectButton v-model="tool.accortool" 
                            required="true" :v="v$.tool.accortool" :class="{'p-invalid': v$.tool.accortool.$invalid }" @blur="v$.tool.accortool.$touch"
                            :options="codesToolType" optionLabel="name" optionValue="code"
                        />
						<small class="p-invalid" v-if="vCheckRequire('accortool')">{{ errorMessages.required }}</small>
					</div>
					<span class="p-field p-float-label">
						<InputText id="accname" v-model.trim.lazy="tool.accname" :v="v$.tool.accname" @blur="v$.tool.accname.$touch"
                            required="true" :class="{'p-invalid': v$.tool.accname.$invalid}" autocomplete="new-tool-accname"
                        />
						<label for="accname">Megnevezés</label>
						<small class="p-invalid" v-if="vCheckRequire('accname')">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field p-float-label">
						<InputText id="accgroup" v-model.trim.lazy="tool.accgroup" :v="v$.tool.accgroup" @blur="v$.tool.accgroup.$touch" 
                            required="true" :class="{'p-invalid': v$.tool.accgroup.$invalid}" autocomplete="new-tool-accgroup"
                        />
						<label for="accgroup">Csoport</label>
						<small class="p-invalid" v-if="vCheckRequire('accgroup')">{{ errorMessages.required }}</small>
					</span>
					<span class="p-field-checkbox">
                        <Checkbox id="oep" v-model="tool.oep" :binary="true"
                        />
						<label for="oep">NEAK támogatott</label>
					</span>
					<span class="p-field p-float-label" style="margin-top: 1.5rem">
						<Textarea id="description" v-model="tool.note" rows="3" cols="20" style="resize: vertical"
                        />
						<label for="description">Megjegyzés</label>
					</span>

					<template #footer>
                        <small class="p-error p-d-block" v-if="v$.$error">{{ errorMessages.formError }}</small>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                            v-tooltip.bottom="'ESC'" tabindex="-1"
                            @click="hideDialog"/>
						<Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success" 
                            v-tooltip.bottom="'Ctrl+ENTER'" :disabled="v$.$error || submitted"
                            @click="saveTool"/>
					</template>
				</Dialog>


                <!-- Törlés kérdés felugró -->

				<Dialog v-model:visible="deleteToolDialog" :style="{width: '450px'}" header="Megerősítés" :modal="true">
					<div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="tool">Biztosan töröljük az eszközt: <b>{{tool.accname}}</b> ?</span>
					</div>
					<template #footer>
						<Button label="Mégse" icon="pi pi-times" class="p-button-text" @click="deleteToolDialog = false"/>
						<Button label="Törlés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" class="p-button-danger" 
                            @click="deleteTool"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
//import { mapGetters, mapActions } from 'vuex'
import ToolService from '@/service/backend/tool.service'
import useVuelidate from '@vuelidate/core'
import { required, errorMessages } from  '@/texts/errorMessages'

export default {
    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },
	data() {
        const codesToolType = [
            {code: 'A', name: 'Eszköz'},
            {code: 'T', name: 'Szerelék'},
            {code: 'P', name: 'Pumpa'}
        ]
        const codesOEP = [
            {code: 'Y', name: 'Igen'},
            {code: 'N', name: '-'},
            {code: '' , name: '-'}
        ]

		return {
			tools: null,
			toolDialog: false,
			deleteToolDialog: false,
			tool: {},
			expandedRows: [],
            filters: {
                'global': {value: null},
            },
            submitted: false,
            searchGlobal: "",
            loading: true,
            loadingSearch: false,
            searchInterval: null,
            errorMessages,

            codesToolType,
            codesOEP

		}
	},
    validations: {
        tool: {
            accname: { required },
            accgroup: { required }, 
            accortool: { required }
        }
    },
    toolService: null,
	created() {
		this.toolService = new ToolService()
    },
    beforeMount() {
        //this.loadCodeStore('toolClass') // Abban az esetben, ha API-s a codeStore - de nem kell ezt tudnia, jöhetne
    },
	mounted() {
        this.loadData();
	},
	methods: {
/*        ...mapActions({
            getCodeStore: 'codeStore/loadCodeStore'
        }), */
        loadData() {
            this.loading = true;

            this.toolService.getTools()
            .then( result => {
                this.tools = result
                this.loading = false
            })
        },
        onFilter() {
            this.loadingSearch = false
        },
        vCheckRequire(field){
            return this.v$.tool[field].$dirty && !this.v$.tool[field].required
        },
        expandRow(e) {
            if(this.expandedRows.length === 0 || this.expandedRows[0].id !== e.data.id ){
                this.expandedRows = this.tools.filter(p => p.id == e.data.id);
            }else{
                this.expandedRows = []
            }
        },
        teszt() {
            this.$toast.add({severity:'success', summary: 'Teszt!', detail: 'Tesztelve!', life: 30000 })
        },
        openDialog(){
            this.toolDialog = true
            this.submitted = false
            this.$nextTick(() => { this.v$.$reset() })
        },
		openNew() {
            this.tool = {}
            this.openDialog()
		},
		hideDialog() {
			this.toolDialog = false
            this.submitted = false
		},
		async saveTool() {

            this.submitted = true;
            this.v$.tool.$touch()

            if (!this.v$.$invalid) {
                    try{
                        const ca = this.tool.accortool
                        this.tool.accortool_name = this.codesToolType.find(x => x.code === ca).name;
                        this.tool.oep = (this.tool.oep) ? 'Y' : ''
                        const co = this.tool.oep
                        this.tool.oep_name       = this.codesOEP.find(x => x.code === co).name;
                        if (this.tool.id) {
                            this.tool.id = await this.toolService.updateTool(this.tool)
                            this.tools[this.findIndexById(this.tool.id)] = this.tool
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Frissítve!', life: 3000})
                        }
                        else {
                            this.tool.id = await this.toolService.newTool(this.tool)
                            this.tools.unshift(this.tool)
                            this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Létrehozva!', life: 3000})
                        }
                        
                    }catch(e){
                        console.log(e)
                        // TODO server oldali hiba kiírása?
                        this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
                        this.submitted = false
                        return
                    }

                    this.submitted = false
                    this.hideDialog()
            
            }

            this.submitted = false

		},
		editTool(id) {
            this.tool = JSON.parse(JSON.stringify(this.tools.find( d => d.id === id)));
            this.tool.oep = (this.tool.oep == 'Y') ? true : false
            this.openDialog()
		},
		confirmDeleteTool(tool) {
			this.tool = tool
			this.deleteToolDialog = true
		},
		async deleteTool() {
            this.submitted = true
            try{
                await this.toolService.deleteTool(this.tool.id)
                this.tools = this.tools.filter(val => val.id !== this.tool.id)
                this.submitted = false
                this.deleteToolDialog = false
                this.tool = {}
                this.$toast.add({severity:'success', summary: 'Siker', detail: 'Törölve!', life: 3000})
            }catch{
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }finally{
                this.submitted = false
            }
		},
		findIndexById(id) {
			let index = -1
			for (let i = 0; i < this.tools.length; i++) {
				if (this.tools[i].id === id) {
					index = i
					break
				}
			}

			return index;
		},
		exportCSV() {
			this.$refs.dt.exportCSV()
		},
        ctrlEntered() {
            //saveTool
            console.log('ctrlEntered()')
        }
    }, 
    watch: {
        searchGlobal(val) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.loadingSearch = true
                setTimeout(() => { this.filters['global'].value = val; this.onFilter(); }, 20)
            }, 600)
        }
    }
}
</script>
<style>
.p-datatable-tbody {
    cursor: pointer;
}
.p-datatable-row-expansion {
    cursor: auto;
}
.p-card .p-card-content {
    padding: 0 0 0.2rem 0;
}
</style>

<style scoped lang="postcss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.tool-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .tool-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.tool-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

#maincard {
    padding-top: 0px;
}

h5 {
    padding-left: 1.6rem;
    padding-bottom: 0.6rem;
}

@media screen and (max-width: 960px) {
	::deep() .p-datatable {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}

	::deep() .p-toolbar {
		flex-wrap: wrap;

		.p-button {
			margin-bottom: .25rem;
		}
    }
    
}
</style>
