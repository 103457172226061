import ApiService from './api.service'

export default class ToolService {

	async getTools() {
        const response = await ApiService.get('api/tools')
        return response.data.data
    }

	async newTool(tool) {
        const response = await ApiService.post('api/tools', tool)
        return response.data.id
    }

	async updateTool(tool) {
        const response = await ApiService.put('api/tools/'+tool.id, tool)
        return response.data.id
    }

	async deleteTool(id) {
        const response = await ApiService.delete('api/tools/'+id)
        return response
    }

}
